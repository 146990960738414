import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

const queryKeys = {
	handle: 'handle',
	wgc: 'wgc',
	version: 'version',
};

export const useQueryParams = () => {
	const [info, setInfo] = useState<{
		isWidget: boolean;
		handle: string;
		wgc: string;
	}>({ isWidget: true, handle: '', wgc: '' });
	const [searchParams] = useSearchParams();
	useEffect(() => {
		const handle = searchParams.get(queryKeys.handle);
		const webGivingConfig = searchParams.get(queryKeys.wgc);
		const version = searchParams.get(queryKeys.version);
		setInfo({
			isWidget: ![version].includes('1'),
			handle: handle ?? '',
			wgc: webGivingConfig ?? '',
		});
	}, [searchParams]);

	return info;
};
