import React from 'react';

import ReactDOM from 'react-dom/client';
import './main.css';

import { LandingPage } from './landing-page';

const rootElement = document.getElementById('root');
if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<LandingPage />
		</React.StrictMode>
	);
}
