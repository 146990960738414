import React from 'react';

import { isQA } from '@src/constants';

import { StaticQAFormPage } from './form-qa';
import { StaticSandboxFormPage } from './form-sandbox';
import { FormScript } from './form-script';

export const StaticFormPage: React.FC = () => (
	<div>
		{isQA() ? <StaticQAFormPage /> : <StaticSandboxFormPage />}
		<FormScript />
	</div>
);
