import { useEffect, useRef } from 'react';

import { isQA, isSandbox } from '@src/constants';

import { useQueryParams } from '../query-params-hook';

export function DynamicScript() {
	const { handle, wgc, isWidget } = useQueryParams();
	const addedRef = useRef(false);

	useEffect(() => {
		if (document.getElementById('pp-form-script') || addedRef.current) {
			return;
		}

		const script = document.createElement('script');
		script.id = 'pp-dynamic-script';

		if (isQA()) {
			script.innerHTML = `!(function () {
                    window.pushpayEmbeddedConfig = {
                        handle:  "${handle || 'embeddynamic'}",
                        wgc: "${
							wgc ||
							'eyJyYnUiOiJodHRwczovL2VtYmVkZGVkLWdpdmluZy1kZW1vLXBhZ2UudGVzdHB1c2hwYXkuY29tLyIsInJidCI6IkJhY2sgdG8gc2l0ZSIsImFza2dwIjp0cnVlfTozTEs1cW1BMGwyZ19fSkFveTVSX1VBN25La2M'
						}",
                    };
                    var e = document.createElement('script');
                    (e.type = 'text/javascript'),
                    (e.async = !0),
                    (e.src = 'https://qa-embedded.testpushpay.com'),
                    document.getElementsByTagName('head')[0].appendChild(e);
                })();
            `;
		}

		if (isSandbox()) {
			script.innerHTML = `!(function () {
                    window.pushpayEmbeddedConfig = {
                        handle:  "${handle || 'embcentralchurch'}",
                        wgc:  "${
							wgc ||
							'eyJyYnUiOiJodHRwczovL2VtYmVkZGVkLWdpdmluZy5jY2h1cmNoLmNvLyIsInJidCI6IkJhY2sgdG8gc2l0ZSIsImFza2dwIjp0cnVlfTpXcDNWcERMczhFbU5DdkhHNWVoZUFySGtVM28'
						}",
                    };
                    var e = document.createElement('script');
                    (e.type = 'text/javascript'),
                    (e.async = !0),
                    (e.src = 'https://embedded.pushpay.io'),
                    document.getElementsByTagName('head')[0].appendChild(e);
                })();
            `;
		}

		document.body.appendChild(script);
		addedRef.current = true;
		// eslint-disable-next-line consistent-return
		return () => {
			document.body.removeChild(script);
			addedRef.current = false;
		};
	}, [handle, wgc, isWidget]);

	return null;
}
