import { FC } from 'react';

import { createUseStyles } from '@pushpay/styles';

import { DynamicScript } from './dynamic-script';

const useStyles = createUseStyles({
	root: {
		position: 'relative',
		paddingTop: '64px',
	},
});

export const Widget: FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<DynamicScript />
			<div id="pushpay-embedded-giving" />
		</div>
	);
};
