/* eslint-disable react/no-danger */
import React from 'react';

import { FormScript } from './form-script';

const formStyle = `
	:root {
		--pp-primary: #00516a;
		--pp-primary-hover: #00455a;
	}

	#pp-embeddedForm {
		min-width: 300px;
		border-radius: 5px;
		text-align: left;
		font-family: sans-serif;
		position: relative;
		display: flex;
		flex-direction: column;
		font-size: 16px;
		background-color: #fff;
		margin: 0px 0px 30px 0px;
		padding: 24px !important;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	}

	#pp-embeddedForm h4 {
		font-weight: 600;
		line-height: 24px;
		margin: 0;
		color: black;
		padding-bottom: 24px;
	}

	#pp-embeddedForm button,
	#pp-embeddedForm input,
	#pp-embeddedForm select {
		height: 40px;
	}

	#pp-amount,
	#pp-occurence {
		-moz-appearance: textfield;
	}

	#pp-amountBox {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}

	#pp-amountBox .pp-amountPrefix {
		position: absolute;
		line-height: 40px;
		left: 16px;
		bottom: 0px;
		height: 40px;
		color: #4b4d53;
		border: 1px transparent solid;
	}

	#pp-amount::-webkit-outer-spin-button,
	#pp-amount::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	#pp-embeddedForm label {
		display: block;
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 0px;
		color: #737478;
		margin-bottom: 8px;
	}

	#pp-embeddedForm input,
	#pp-embeddedForm select {
		font-size: 16px;
		border: 1px solid #ccc;
		border-radius: 4px;
		display: block;
		padding: 0px;
		background-color: #fff;
		color: #4b4d53;
		cursor: pointer;
	}

	#pp-embeddedForm select,
	#pp-endDate,
	#pp-occurence,
	#pp-startDate {
		width: 100%;
		padding: 0px 0 0 10px;
		margin-bottom: 24px;
	}

	#pp-startDate,
	#pp-endDate,
	#pp-embeddedForm #pp-occurence {
		text-indent: 6px;
		padding-left: 6px;
	}

	#pp-amountBox input {
		text-indent: 28px;
		color: #4d4d4d;
	}

	#pp-formErrors {
		border: 1px red solid;
		border-radius: 4px;
		border-top-width: 4px;
		margin-bottom: 16px;
		padding: 10px;
		font-size: 13px;
	}

	#pp-embeddedForm input[type='date'] {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
	}

	#pp-embeddedForm input[type='submit'] {
		border: none;
		width: 100%;
		cursor: pointer;
		border-radius: 45px;
		background-color: var(--pp-primary);
		color: #fff;
		text-indent: 0;
		min-width: 100px;
		min-height: 40px;
		margin: 0;
	}

	#pp-embeddedForm input[type='submit']:hover {
		background-color: var(--pp-primary-hover);
	}

	#pp-tabContainer {
		display: flex;
		justify-content: space-around;
		margin-bottom: 24px;
	}

	#pp-tabContainer button {
		flex: 1;
		font-size: 16px;
		background-color: transparent;
		border: 1px solid #ccc;
		padding: 10px;
		cursor: pointer;
		background-color: white;
		transition: background-color 0.3s;
	}

	#pp-tabContainer button.pp-active {
		background-color: var(--pp-primary);
		color: white;
		border-color: var(--pp-primary);
	}

	#pp-tabContainer button.pp-active:hover {
		background-color: var(--pp-primary-hover);
	}

	.pp-hidden {
		display: none !important;
	}

	#pp-tabOneTime {
		border-radius: 4px 0 0 4px;
		margin-right: -1px;
	}

	#pp-tabRecurring {
		border-radius: 0 4px 4px 0;
	}

	#pp-oneTime.pp-active,
	#pp-recurring.pp-active {
		display: block;
	}

	#pp-embeddedForm .pp-required {
		color: red;
		position: relative;
		display: inline-block;
	}

	#pp-separator {
		padding: 24px 0;
		text-align: center;
		position: relative;
		display: flex;
	}

	#pp-separator hr {
		border: none;
		height: 1px;
		background-color: #ccc;
		flex: 1;
	}

	#pp-separator span {
		padding: 0px 12px;
	}

	#pp-stockAndCrypto {
		text-align: center;
	}

	#pp-engivenLinks {
		display: flex;
		gap: 16px;
	}

	#pp-engivenLinks a {
		line-height: 16px;
		padding: 12px;
		border-radius: 24px;
		color: var(--pp-primary);
		text-decoration: none;
		border: 1px var(--pp-primary) solid;
		cursor: pointer;
		display: flex;
		flex: 1;
		text-align: center;
		justify-content: center;
	}

	#pp-engivenLinks a:hover {
		color: var(--pp-primary-hover);
		border: 1px var(--pp-primary-hover) solid;
	}

	#pp-engivenInfo {
		font-size: 14px;
		line-height: 18px;
		visibility: hidden;
		height: 0;
		overflow: hidden;
	}

	#pp-engivenInfo a {
		color: var(--pp-primary);
		padding: 0 4px;
	}

	#pp-engivenInfo p {
		margin: 0 0 16px;
	}

	#pp-stockAndCrypto:hover #pp-engivenInfo {
		visibility: visible;
		height: auto;
		padding: 24px 0;
	}

	#pp-poweredBy {
		color: #91949C;
		font-size: 12px;
		display: flex;
		height: 16px;
		line-height: 16px;
		align-items: center;
		justify-content: center;
		padding-top: 24px;
	}

	#pp-poweredBy img {
		margin-left: 5px;
		opacity: 0.6;
	}

	@media (max-width: 576px) {
		#pp-engivenInfo {
			visibility: visible;
			height: auto;
			padding: 25px 0;
			line-height: 25px;
			font-size: 16px;
		}

		#pp-engivenInfo a,
		#pp-engivenInfo small {
			line-height: 25px;
			font-size: 16px;
		}
	}
`;

const formHtml = `
	<form id="pp-embeddedForm" action="https://sandbox.pushpay.io/g/embcentralchurch" method="get"><input type="hidden"
		name="src" value="embhtml" /><input type="hidden" name="wgc"
		value="eyJyYnUiOiJodHRwczovL2VtYmVkZGVkLWdpdmluZy5jY2h1cmNoLmNvLyIsInJidCI6IlJldHVybiB0byBzaXRlIiwiYXNrZ3AiOnRydWV9OldEMHFXM0otQU03QWJyOFBZczhsbkdEYjVjbw" />
	<h4>Give now</h4>
	<div id="pp-formErrors" class="pp-hidden">---</div>
	<div id="pp-amountBox"><label for="pp-amount">I&#x27;d like to give <i class="pp-required">*</i></label><span
			class="pp-amountPrefix">$</span><input type="number" required="" id="pp-amount" name="a"
			placeholder="0.00" step="0.01" max="2000" min="1" /></div>
	<div id="pp-tabContainer"><button type="button" id="pp-tabOneTime" class="">One time</button><button
			type="button" class="pp-active" id="pp-tabRecurring">Recurring</button></div>
	<div id="pp-oneTime"><input type="hidden" disabled="" name="r" id="pp-giveOnce" value="one" /></div>
	<div id="pp-recurring" class="">
		<div><label for="pp-recurrence">Frequency <i class="pp-required">*</i></label><select id="pp-recurrence"
				required="" name="r">
				<option value="Weekly">Every week</option>
				<option value="Fortnightly">Every 2 weeks</option>
				<option value="Monthly">Every month</option>
				<option value="FirstAndFifteenth">1st and 15th monthly</option>
			</select></div>
		<div><label for="pp-startDate">Starting <i class="pp-required">*</i></label><input type="date"
				id="pp-startDate" name="rsd" required="" /></div>
		<div><label for="pp-endTypes">Ending <i class="pp-required">*</i></label><select id="pp-endTypes"
				required="" name="ret">
				<option value="0">When I cancel it</option>
				<option value="1">On a specific date</option>
				<option value="2">After number of gifts</option>
			</select>
			<div id="pp-endDateContainer" class="pp-hidden"><label for="pp-endDate">End date <i
						class="pp-required">*</i></label><input type="date" disabled="" required="" id="pp-endDate"
					name="red" /></div>
			<div id="pp-occurenceContainer" class="pp-hidden"><label for="pp-occurence">Number of giving <i
						class="pp-required">*</i></label><input type="number" max="1000" min="1" required=""
					placeholder="e.g. 1-1000" spellcheck="false" id="pp-occurence" disabled="" name="reo" /></div>
		</div>
	</div>
	<div><label for="pp-fundInput">Towards this fund <i class="pp-required">*</i></label><select id="pp-fundInput"
			name="fnd" required="">
			<option selected="" value="Woyi-_REbYI1Kd1GqZrbag">Tithes &amp; Offerings</option>
			<option value="xYt3h7mRasyezVbXEuW5rQ">Building Project </option>
			<option value="1nad9DszTBKUFtnxt_Lpng">Food Pantry</option>
			<option value="m_WM5gzKs4PJ3LST926mSA">Missions</option>
			<option value="fYxchM8w3lXfM5-TLYsYtw">Youth Ministry</option>
			<option value="tVlJOjGLzlVvuVhBhmQMeQ">Mt. Sinai Campaign </option>
			<option value="0-gO6EkE4LTE0wb6SWJPhg">City serve</option>
			<option value="flq6i3tFd_BLTmUgi_4Xew">2021 Annual Fall Appeal</option>
			<option value="njE1BBC6C87yHkzeAN-rRg">Dream Center Campaign for Riverside</option>
		</select></div><input type="submit" id="pp-submitButton" value="Next" />
	<div id="pp-poweredBy">Powered by <img height="20"
			src="https://sandbox.pushpay.io/Content/LoggedInWeb/assets/svg/pushpay-logo-grey.svg"
			alt="powered by Pushpay" /></div><img width="1" height="1"
		src="https://sandbox.pushpay.io/Content/Beacons/eb.gif?src=embhtml" />
	</form>
`;

export const StaticSandboxFormPage: React.FC = () => (
	<div>
		<style dangerouslySetInnerHTML={{ __html: formStyle }} />
		<div dangerouslySetInnerHTML={{ __html: formHtml }} />
		<FormScript />
	</div>
);
