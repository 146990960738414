import React from 'react';

import { createUseStyles } from '@pushpay/styles';

import { Footer, Navigation, Backdrop, StaticFormPage } from './components';
import { breakSizes } from './constants';

const useStyles = createUseStyles({
	root: {
		position: 'relative',
	},
	body: {
		position: 'relative',
		zIndex: 1,
		minHeight: '50vw',
	},
	form: {
		paddingTop: 30,
		paddingBottom: 60,

		[`@media (min-width:${breakSizes.md})`]: {
			maxWidth: 460,
			margin: '0 auto',
		},
	},
	content: {
		paddingTop: 24,
		maxWidth: 460,
		margin: 'auto',
		[`@media (min-width:${breakSizes.md})`]: {
			paddingTop: 64,
		},
	},
	wrap: {
		margin: 'auto',
	},
	title: {
		fontSize: '36px',
		fontWeight: 600,
		color: 'white',
		margin: '36px 0 36px 0',
	},
	subtitle: {
		fontSize: '24px',
		fontWeight: 300,
		color: 'white',
		margin: 0,
	},
});

export const LandingPage: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Backdrop />
			<Navigation />
			<div className={`${classes.body} container`}>
				<div className="row">
					<div className="col-md-12 col-lg-6">
						<div className={classes.content}>
							<p className={classes.title}>
								Your generosity will create ripples of positive change, touching hearts and transforming
								lives.
							</p>
							<p className={classes.subtitle}>
								Easy and safe. Donate once or set up regular payments with your checking account, debit,
								or credit card.
							</p>
						</div>
					</div>
					<div className="col-md-12 col-lg-6">
						<div className={classes.form}>
							<StaticFormPage />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
