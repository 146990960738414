import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './main.css';

import { LandingPage } from './landing-page';

const rootElement = document.getElementById('root');
if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<BrowserRouter>
				<Routes>
					<Route element={<LandingPage />} path="/" />
					<Route element={<LandingPage />} path="*" />
				</Routes>
			</BrowserRouter>
		</React.StrictMode>
	);
}
