import React from 'react';

import { createUseStyles } from '@pushpay/styles';

import { breakSizes, isQA } from '@src/constants';

import backdropImageQA from '../assets/backdrop-qa.jpg';
import backdropImageSandbox from '../assets/backdrop-sandbox.jpg';

const useStyles = createUseStyles({
	root: {
		zIndex: '1',
		position: 'fixed',
		height: '200%',
		width: '200%',
		top: '-50%',
		left: '-50%',
		backgroundImage: `url(${isQA() ? backdropImageQA : backdropImageSandbox})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	image: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		minWidth: '50%',
		minHeight: '50%',
		transform: 'translate3d(-50%, -50%, 0)',
	},
	gradient: {
		backgroundColor: `rgba(0, 0, 0, 0.3)`,
		backgroundImage: `linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0) 60%,
			rgba(0, 0, 0, 0.5) 100%
		)`,
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		[`@media (max-width:${breakSizes.sm})`]: {
			backgroundColor: `rgba(0, 0, 0, 0.5)`,
			backgroundImage: `linear-gradient(
				90deg,
				rgba(0, 0, 0, 0.5) 0%,
				rgba(0, 0, 0, 0) 40%,
				rgba(0, 0, 0, 0) 60%,
				rgba(0, 0, 0, 0.5) 100%
			)`,
		},
	},
});

export const Backdrop: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<img alt="backdrop" className={classes.image} src={isQA() ? backdropImageQA : backdropImageSandbox} />
			<div className={classes.gradient} />
		</div>
	);
};
