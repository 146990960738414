declare const APP_ENV: string;

export const breakSizes = {
	xs: 0,
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1400px',
};

export const breakpoints = {
	xs: `@media (max-width: ${breakSizes.sm})`,
	sm: `@media (min-width: ${breakSizes.sm} && max-width:${breakSizes.md})`,
	md: `@media (min-width: ${breakSizes.md} && max-width:${breakSizes.lg})`,
	lg: `@media (min-width: ${breakSizes.lg} && max-width:${breakSizes.xl})`,
	xl: `@media (min-width: ${breakSizes.xl} && max-width:${breakSizes.xxl})`,
	xxl: `@media (min-width: ${breakSizes.xxl})`,
	mobile: `@media (max-width: ${breakSizes.sm})`,
	tablet: `@media (max-width: ${breakSizes.md})`,
	desktop: `@media (min-width: ${breakSizes.md})`,
};

export const isQA = () => APP_ENV === 'test';
export const isSandbox = () => APP_ENV === 'sandbox';
export const colors = isQA()
	? {
			primary: '#DD5D00',
			secondary: '',
			white: '#FFF',
	  }
	: {
			primary: '#00516a',
			secondary: '#00455a',
			white: '#FFF',
	  };
