import { useEffect } from 'react';

export function FormScript() {
	useEffect(() => {
		const script = document.createElement('script');
		script.innerHTML = `
		/* --- DOM ELEMENTS --- */
		var ppForm = document.getElementById('pp-embeddedForm');
		var ppAmount = document.getElementById('pp-amount');
		var ppStartDate = document.getElementById('pp-startDate');
		var ppEndTypes = document.getElementById('pp-endTypes');

		var ppEndDateContainer = document.getElementById('pp-endDateContainer');
		var ppEndDate = document.getElementById('pp-endDate');

		var ppOccurenceContainer = document.getElementById('pp-occurenceContainer');
		var ppOccurence = document.getElementById('pp-occurence');
		var ppRecurrence = document.getElementById('pp-recurrence');

		var ppGiveOnce = document.getElementById('pp-giveOnce');
		var ppTabOneTimeBtn = document.getElementById('pp-tabOneTime');
		var ppTabRecurringBtn = document.getElementById('pp-tabRecurring');
		var ppRecurringContainer = document.getElementById('pp-recurring');

		var ppFormErrors = document.getElementById('pp-formErrors');
		var ppFund = document.getElementById('pp-fundInput');

		var payerCanSpecifyEndType = ppEndTypes;

		/* --- INITIALISERS  --- */

		ppSetStartDate(ppStartDate);

		/* --- FUNCTIONS --- */

		function ppIsDateInPast(date) {
			return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		}

		function ppSetEndDateMinimum() {
			var minimumDate = new Date(ppStartDate.value);
			minimumDate.setDate(minimumDate.getDate() + 1);

			ppEndDate.setAttribute('min', minimumDate.toISOString().split('T')[0]);
		}

		function ppGetSetStartDateMinimum() {
			var td = new Date();
			var month = td.getMonth() + 1;
			var day = td.getDate();
			var year = td.getFullYear();
			if (month < 10) month = '0' + month.toString();
			if (day < 10) day = '0' + day.toString();
			return year + '-' + month + '-' + day;
		}

		function ppSetStartDate(elem) {
			var min = ppGetSetStartDateMinimum();
			elem.valueAsDate = new Date(min);
			elem.setAttribute('min', min);
		}

		function ppStartDateOnChange(e) {
			if (ppIsDateInPast(new Date(e.target.value))) {
				e.target.valueAsDate = new Date();
			}

			if (!ppEndDate.disabled) {
				if (!ppValidateStartAndEndDates()) {
					ppEndDate.value = new Date();
				}

				ppSetEndDateMinimum();
			}
		}

		function ppHideElement(elem) {
			if (!elem.classList.contains('pp-hidden')) {
				elem.classList.add('pp-hidden');
			}
		}

		function ppShowElement(elem) {
			if (elem.classList.contains('pp-hidden')) {
				elem.classList.remove('pp-hidden');
			}
		}

		function ppActivateElement(elem) {
			if (!elem.classList.contains('pp-active')) {
				elem.classList.add('pp-active');
			}
		}

		function ppDeactivateElement(elem) {
			if (elem.classList.contains('pp-active')) {
				elem.classList.remove('pp-active');
			}
		}

		function ppEndTypesOnChange(e) {
			ppOccurence.disabled = true;
			ppEndDate.disabled = true;

			switch (e.target.value) {
				case '1':
					ppHideElement(ppOccurenceContainer);
					ppShowElement(ppEndDateContainer);
					ppEndDate.disabled = false;
					ppSetEndDateMinimum();
					break;
				case '2':
					ppHideElement(ppEndDateContainer);
					ppShowElement(ppOccurenceContainer);
					ppOccurence.disabled = false;
					break;
				default:
					ppHideElement(ppOccurenceContainer);
					ppHideElement(ppEndDateContainer);
					break;
			}
		}

		function disableEndTypes(isOneTimeTabSelected) {
			ppEndTypes.disabled = isOneTimeTabSelected;

			if (isOneTimeTabSelected) {
				ppEndDate.disabled = isOneTimeTabSelected;
				ppOccurence.disabled = isOneTimeTabSelected;
			} else {
				const ppEndTypesDateOption = '1';
				const ppEndTypesOccurenceOption = '2';
				ppEndDate.disabled = ppEndTypes.value !== ppEndTypesDateOption;
				ppOccurence.disabled = ppEndTypes.value !== ppEndTypesOccurenceOption;
			}
		}

		function ppOnTabClick(e) {
			const tab = e.target;
			const tabId = tab.id;

			ppActivateElement(tab);
			if (tabId === ppTabOneTimeBtn.id) {
				ppDeactivateElement(ppTabRecurringBtn);
				ppHideElement(ppRecurringContainer);
				ppRecurrence.disabled = true;
				ppGiveOnce.disabled = false;
				ppStartDate.disabled = true;

				if (payerCanSpecifyEndType) {
					disableEndTypes(true)
				}
			} else {
				ppShowElement(ppRecurringContainer);
				ppDeactivateElement(ppTabOneTimeBtn);
				ppRecurrence.disabled = false;
				ppGiveOnce.disabled = true;
				ppStartDate.disabled = false;

				if (payerCanSpecifyEndType) {
					disableEndTypes(false)
				}
			}
		}

		function ppShowError(msg) {
			ppShowElement(ppFormErrors);
			ppFormErrors.innerText = msg;
		}

		function ppHideErrors() {
			ppFormErrors.innerText = '';
			ppHideElement(ppFormErrors);
		}

		function ppValidateAmount() {
			const amount = ppAmount.value;
			return parseInt(amount) > 0;
		}

		function ppValidateFund() {
			return ppFund.value ? true : false;
		}

		function ppValidateRecurrence() {
			return ppRecurrence.value ? true : false;
		}

		function ppAmountOnWheel(e) {
			e.target.blur();
		}

		function ppValidateOccurences() {
			if (ppOccurence.disabled) {
				return true;
			}

			if (ppOccurence.value > 1000 || ppOccurence.value < 1) {
				return false;
			}

			return true;
		}

		function ppValidateStartAndEndDates() {
			if (ppEndDate.disabled) {
				return true;
			}

			return new Date(ppStartDate.value).setHours(0, 0, 0, 0) < new Date(ppEndDate.value).setHours(0, 0, 0, 0);
		}

		function ppFormOnSubmit(e) {
			ppHideErrors();
			var ppFormHasErrors = false;
			const isRecurring = ppTabRecurringBtn.classList.contains('pp-active');

			if (!ppValidateAmount()) {
				ppShowError('Please enter an amount');
				ppFormHasErrors = true;
			}

			if (!ppValidateFund()) {
				ppShowError('Please select a fund');
				ppFormHasErrors = true;
			}

			if (isRecurring && !ppValidateRecurrence()) {
				ppShowError('Please select a frequency');
				ppFormHasErrors = true;
			}

			if (isRecurring && !!ppStartDate.value === false) {
				ppShowError('Please enter a valid start date');
				ppFormHasErrors = true;
			}

			if (isRecurring && ppIsDateInPast(new Date(ppStartDate.value))) {
				ppShowError('Start date cannot be in the past');
				ppFormHasErrors = true;
			}

			if (payerCanSpecifyEndType) {
				if (isRecurring && !ppValidateOccurences()) {
					ppShowError('Please enter number of occurences between 1-1000');
					ppFormHasErrors = true;
				}

				if (isRecurring && !ppValidateStartAndEndDates()) {
					ppShowError('End date must be 1 day after start date or later');
					ppFormHasErrors = true;
				}

			}

			if (ppFormHasErrors) {
				e.preventDefault();
				return false;
			}
		}

		/* --- EVENT LISTENERS --- */
		ppTabOneTimeBtn.addEventListener('click', ppOnTabClick);
		ppTabRecurringBtn.addEventListener('click', ppOnTabClick);

		ppStartDate.addEventListener('change', ppStartDateOnChange);
		ppStartDate.addEventListener('click', function (event) {
			event.target.showPicker();
		});
		ppAmount.addEventListener('wheel', ppAmountOnWheel);
		ppForm.addEventListener('submit', ppFormOnSubmit);

		if (payerCanSpecifyEndType) {
			ppEndDate.addEventListener('change', ppStartDateOnChange);
			ppEndDate.addEventListener('click', function (event) {
				event.target.showPicker();
			});
			ppEndTypes.addEventListener('change', ppEndTypesOnChange);
		}
	`;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return null;
}
