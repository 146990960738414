import React, { useCallback } from 'react';

import { createUseStyles, clsx } from '@pushpay/styles';

import logoQA from '../assets/logo-qa.svg';
import logoSandbox from '../assets/logo-sandbox.svg';
import menuIcon from '../assets/menu.svg';
import { colors, breakSizes, isQA } from '../constants';

const useStyles = createUseStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		backgroundColor: 'white',
		position: 'sticky',
		top: 0,
		zIndex: 5,
		boxShadow: `rgba(99, 99, 99, 0.2) 0px 0px 8px 0px`,
	},
	left: {
		display: 'flex',
		flex: 0,
		padding: '0 16px',
		flexWrap: 'nowrap',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 200,
		[`@media (max-width:${breakSizes.xl})`]: {
			flex: 1,
		},
	},
	middle: {
		display: 'flex',
		flex: 1,
		gap: 10,
		alignItems: 'center',
		justifyContent: 'center',
		[`@media (max-width:${breakSizes.xl})`]: {
			display: 'none',
		},
	},
	right: {
		display: 'flex',
		flex: 0,
		padding: '0 16px',
		alignItems: 'center',
		justifyContent: 'end',
		minWidth: 200,
		[`@media (max-width:${breakSizes.xl})`]: {
			display: 'none',
		},
	},
	logo: {
		color: colors.primary,
		height: 24,
	},
	title: {
		fontSize: '16px',
		fontWeight: 700,
		lineHeight: '52px',
		margin: '0 5px',
		color: colors.primary,
		whiteSpace: 'nowrap',
	},
	link: {
		fontWeight: 400,
		margin: '0 16px',
		whiteSpace: 'nowrap',
	},
	linkActive: {
		color: colors.primary,
		fontWeight: 600,
	},
	menu: {
		width: 24,
	},
});

export const Navigation: React.FC = () => {
	const classes = useStyles();
	const getHeaderInfo = useCallback(() => {
		if (isQA()) {
			return (
				<>
					<img alt="Central Church logo" className={classes.logo} src={logoQA} />
					<span className={classes.title}>Central Church</span>
				</>
			);
		}
		return (
			<>
				<img alt="Central Church logo" className={classes.logo} src={logoSandbox} />
				<span className={classes.title}>Central Church</span>
			</>
		);
	}, [classes]);

	return (
		<div className={classes.root}>
			<div className={classes.left}>{getHeaderInfo()}</div>

			<div className={classes.middle}>
				<span className={classes.link}>Sermons</span>
				<span className={classes.link}>Locations</span>
				<span className={classes.link}>About</span>
				<span className={classes.link}>Get Involved</span>
				<span className={clsx(classes.link, classes.linkActive)}>Giving</span>
			</div>
			<div className={classes.right}>
				<img alt="Menu" className={classes.menu} src={menuIcon} />
			</div>
		</div>
	);
};
