import React from 'react';

import { createUseStyles } from '@pushpay/styles';

import { isQA } from '@src/constants';

const useStyles = createUseStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		flexWrap: 'nowrap',
		backgroundColor: `rgba(0, 0, 0, .6)`,
		height: 60,
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 5,
	},
	title: {
		fontSize: 12,
		color: 'grey',
		margin: '0 auto',
		whiteSpace: 'nowrap',
	},
});

export const Footer: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.title}>
				All rights reserved <span> - {isQA() ? 'qa' : 'sb'}</span>
			</div>
		</div>
	);
};
