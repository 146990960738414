import React from 'react';

import { NavLink } from 'react-router-dom';

import { clsx, createUseStyles } from '@pushpay/styles';

import { isQA } from '@src/constants';

import { useQueryParams } from './query-params-hook';

const useStyles = createUseStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		flexWrap: 'nowrap',
		backgroundColor: `rgba(0, 0, 0, .6)`,
		height: 60,
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 5,
	},
	title: {
		fontSize: 12,
		color: 'grey',
		margin: '0 auto',
		whiteSpace: 'nowrap',
	},
	active: {
		color: 'white',
		fontWeight: 'bold',
	},
	inactive: {
		color: 'grey',
	},
	link: {
		padding: '0 10px',
		fontStyle: 'italic',
	},
});

export const versionLinks = [
	{
		to: '/?version=1',
		label: 'html-form',
		isDynamic: false,
	},
	{
		to: '/?version=2',
		label: 'dynamic-form',
		isDynamic: true,
	},
];

export const FooterLinks: React.FC = ({ className }: { className?: string }) => {
	const classes = useStyles();
	const { isWidget } = useQueryParams();

	return (
		<>
			{versionLinks.map(({ to, label, isDynamic }) => (
				<NavLink
					key={to}
					className={clsx(
						classes.link,
						(Boolean(!isWidget && !isDynamic) && classes.active) || Boolean(isWidget && isDynamic)
							? classes.active
							: classes.inactive,
						className
					)}
					to={to}
				>
					{label}
				</NavLink>
			))}
		</>
	);
};

export const Footer: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.title}>
				All rights reserved <span> - {isQA() ? 'qa' : 'sb'}</span>
				<FooterLinks />
			</div>
		</div>
	);
};
